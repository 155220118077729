import { createSlice } from '@reduxjs/toolkit';
import {
  getInstagramAIConfiguration,
  generateInstagramAIDescription,
  getIfPlanUpgradeWasRequested,
  getIsUserLoggedOnInstagram,
  postInstagramPublication,
  getInstagramEntityData,
} from './services';
import initialState from './initialState';
import {
  checkIfIsHashtagsError,
  checkIfIsMentionsError,
  getCountStatus,
  getErrorTextKey,
} from '@/components/InstagramPost/utils';
import sanitizeHtml from '@/components/WysiwygEditor/utils/sanitizeHtml';
import convertHtmlToText from '@/components/WysiwygEditor/utils/convertHtmlToText';

const MAX_STEPS = 2;

export const InstagramPostSlice = createSlice({
  name: 'InstagramPost',
  initialState,
  reducers: {
    setInstagramPostData(state, action) {
      const { key, subkey, value } = action.payload;
      if (!key) return;

      if (subkey) {
        state[key][subkey] = value;
        return;
      }

      state[key] = value;

      if (key === 'step') {
        state.previewIndex = 0;
      }
    },
    setInstagramPostDescription(state, action) {
      const newDescription = action.payload;
      const countStatus = getCountStatus(newDescription);
      const isMentionsError = checkIfIsMentionsError(newDescription);
      const isHashtagsError = checkIfIsHashtagsError(newDescription);
      const isError =
        countStatus === 'error' || isMentionsError || isHashtagsError;
      const errorTextKey = getErrorTextKey(
        countStatus,
        isMentionsError,
        isHashtagsError
      );

      const isCurrentErrorKeyDifferent =
        state.description.errorTextKey !== errorTextKey;
      const shouldShowErrorBanner =
        isError &&
        (state.description.showErrorBanner ||
          ((isHashtagsError || isMentionsError) && isCurrentErrorKeyDifferent));

      const description = {
        value: newDescription,
        errorTextKey,
        showErrorBanner: shouldShowErrorBanner,
        error: isError,
      };

      state.description = description;

      if (isError || shouldShowErrorBanner) {
        state.ai.showSuccessBanner = false;
      }

      InstagramPostSlice.caseReducers.validateInstagramPostStep(state, {
        type: 'InstagramPost/validateInstagramPostStep',
      });
    },
    setInstagramPostItems(state, action) {
      const items = action.payload;
      const checkedItems = items.filter((item) => item.checked);
      const itemsAreReady =
        state.entity.itemsAreReady || items.every((item) => !item.loading);

      state.entity = { ...state.entity, items, itemsAreReady, checkedItems };

      InstagramPostSlice.caseReducers.validateInstagramPostStep(state, {
        type: 'InstagramPost/validateInstagramPostStep',
      });
    },
    validateInstagramPostStep(state) {
      const canMoveForward =
        !state.entity.error &&
        !state.description.error &&
        state.entity.checkedItems.length > 0;

      state.canMoveToNextStep = canMoveForward;
    },
    handleInstagramPostPrevStep(state) {
      if (state.step > 1) {
        state.step -= 1;
      } else {
        window.history.back();
      }
    },
    handleInstagramPostNextStep(state) {
      if (state.description.error) {
        state.description.showErrorBanner = true;
        return;
      }

      if (state.step < MAX_STEPS && state.canMoveToNextStep) {
        state.step += 1;
      }
    },
    handleSelectAIOption(state, action) {
      const { field, id } = action.payload;

      state.ai.fields = {
        ...state.ai.fields,
        [field]: {
          ...state.ai.fields[field],
          options: state.ai.fields[field].options.map((option) => ({
            ...option,
            isActive: option.id === id,
          })),
        },
      };
    },
    handleResetAIOptions(state) {
      state.ai.fields = initialState.ai.fields;
      const { textLength, textStyle, emojiUsage } =
        state.ai.lastSavedConfiguration;

      InstagramPostSlice.caseReducers.updateInstagramAIFields(state, {
        type: 'InstagramPost/updateInstagramAIFields',
        payload: { textLength, textStyle, emojiUsage },
      });
    },
    updateInstagramAIFields(state, action) {
      const { textLength, textStyle, emojiUsage } = action.payload;

      const textLengthOptions = state.ai.fields.textLength.options.map(
        (option) => ({
          ...option,
          isActive: option.id === textLength,
        })
      );

      const emojiUsageOptions = state.ai.fields.emojiUsage.options.map(
        (option) => ({
          ...option,
          isActive: option.id === emojiUsage,
        })
      );

      const textStyleOptions = state.ai.fields.textStyle.options.map(
        (option) => ({
          ...option,
          isActive: option.id === textStyle,
        })
      );

      const updatedFields = {
        textLength: {
          ...state.ai.fields.textLength,
          options: textLengthOptions,
        },
        emojiUsage: {
          ...state.ai.fields.emojiUsage,
          options: emojiUsageOptions,
        },
        textStyle: {
          ...state.ai.fields.textStyle,
          options: textStyleOptions,
        },
      };

      state.ai.fields = updatedFields;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInstagramEntityData.pending, (state) => {
      state.entity = {
        ...state.entity,
        error: false,
        loading: true,
        images: null,
      };
    });
    builder.addCase(getInstagramEntityData.fulfilled, (state, action) => {
      const { pictures } = action.payload.data;
      const images = [];
      const entityType =
        action.meta.arg.type === 'PRO' ? 'property' : 'development';

      if (pictures.front_cover_image?.url) {
        images.push(pictures.front_cover_image.url);
      }

      if (pictures.images?.length > 0) {
        images.push(...pictures.images);
      }

      if (pictures.blueprints?.length > 0) {
        images.push(...pictures.blueprints);
      }

      state.canVisualizeEntity = true;
      state.entity = {
        ...state.entity,
        loading: false,
        images,
        error: false,
        type: entityType,
        id: action.payload.data.id,
      };

      let { description } = action.payload.data;

      // the development description comes as plain text,
      // and the property description comes as html
      if (entityType === 'property') {
        const sanitizedDescription = sanitizeHtml(
          action.payload.data.description
        );
        description = convertHtmlToText(sanitizedDescription);
      }

      InstagramPostSlice.caseReducers.setInstagramPostDescription(state, {
        type: 'InstagramPost/setInstagramPostDescription',
        payload: description,
      });
    });
    builder.addCase(getInstagramEntityData.rejected, (state) => {
      state.canVisualizeEntity = false;
      state.entity = { ...state.entity, error: true, loading: false };
    });

    builder.addCase(postInstagramPublication.pending, (state) => {
      state.posting.loading = true;
    });
    builder.addCase(postInstagramPublication.fulfilled, (state) => {
      const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
      const entityUrl = `${tokkoBrokerHost}/${state.entity.type}/${state.entity.id}?instagram_status=1`;
      window.location.href = entityUrl;
    });
    builder.addCase(postInstagramPublication.rejected, (state) => {
      const tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
      const entityUrl = `${tokkoBrokerHost}/${state.entity.type}/${state.entity.id}?instagram_status=2`;
      window.location.href = entityUrl;
    });
    builder.addCase(getIsUserLoggedOnInstagram.fulfilled, (state, action) => {
      const { has_access, username, has_reached_posting_limit } =
        action.payload;
      state.isLogged = has_access;
      state.username = username;
      state.postLimitReached = has_reached_posting_limit;
    });
    builder.addCase(getIsUserLoggedOnInstagram.rejected, (state) => {
      state.isLogged = false;
    });
    builder.addCase(generateInstagramAIDescription.pending, (state) => {
      state.ai = {
        ...state.ai,
        loading: true,
        success: false,
        error: false,
      };

      if (state.ai.saveForNextTime) {
        const textLength = state.ai.fields.textLength.options.find(
          (el) => el.isActive
        )?.id;

        const textStyle = state.ai.fields.textStyle.options.find(
          (el) => el.isActive
        )?.id;

        const emojiUsage = state.ai.fields.emojiUsage.options.find(
          (el) => el.isActive
        )?.id;

        state.ai.lastSavedConfiguration = {
          textLength,
          textStyle,
          emojiUsage,
        };
      } else {
        InstagramPostSlice.caseReducers.handleResetAIOptions(state, {
          type: 'InstagramPost/handleResetAIOptions',
        });
      }
    });
    builder.addCase(
      generateInstagramAIDescription.fulfilled,
      (state, action) => {
        state.ai = {
          ...state.ai,
          loading: false,
          success: true,
          error: false,
          showSuccessBanner: true,
        };

        InstagramPostSlice.caseReducers.setInstagramPostDescription(state, {
          type: 'InstagramPost/setInstagramPostDescription',
          payload: action.payload.description,
        });
      }
    );
    builder.addCase(generateInstagramAIDescription.rejected, (state) => {
      state.ai = {
        ...state.ai,
        loading: false,
        success: true,
        error: true,
      };
    });
    builder.addCase(getIfPlanUpgradeWasRequested.pending, (state) => {
      state.ai.isCheckingPlan = true;
    });
    builder.addCase(getIfPlanUpgradeWasRequested.fulfilled, (state, action) => {
      state.ai = {
        ...state.ai,
        isCheckingPlan: false,
        wasFullPlanRequested: action.payload.instagram,
      };
    });
    builder.addCase(getIfPlanUpgradeWasRequested.rejected, (state) => {
      state.ai = {
        ...state.ai,
        isCheckingPlan: false,
        wasFullPlanRequested: false,
      };
    });
    builder.addCase(getInstagramAIConfiguration.pending, (state) => {
      state.ai.isCheckingForPreviousResults = true;
      state.ai.werePreviousResults = false;
    });
    builder.addCase(getInstagramAIConfiguration.fulfilled, (state, action) => {
      const { social_media_description, openai_configuration } = action.payload;
      const defaultOption = 2;

      const textLength = openai_configuration?.text_length ?? defaultOption;
      const textStyle = openai_configuration?.text_style ?? defaultOption;
      const emojiUsage = openai_configuration?.emoji_usage ?? defaultOption;

      state.ai.isCheckingForPreviousResults = false;
      state.ai.werePreviousResults = Boolean(social_media_description);
      state.ai.lastSavedConfiguration = {
        textLength,
        textStyle,
        emojiUsage,
      };

      InstagramPostSlice.caseReducers.updateInstagramAIFields(state, {
        type: 'InstagramPost/updateInstagramAIFields',
        payload: { textLength, textStyle, emojiUsage },
      });

      InstagramPostSlice.caseReducers.setInstagramPostDescription(state, {
        type: 'InstagramPost/setInstagramPostDescription',
        payload: social_media_description ?? state.description.value,
      });
    });
    builder.addCase(getInstagramAIConfiguration.rejected, (state) => {
      state.ai.isCheckingForPreviousResults = false;
      state.ai.werePreviousResults = false;
    });
  },
});

export const {
  setInstagramPostData,
  setInstagramPostDescription,
  setInstagramPostItems,
  handleInstagramPostPrevStep,
  handleInstagramPostNextStep,
  handleSelectAIOption,
  handleResetAIOptions,
} = InstagramPostSlice.actions;

export default InstagramPostSlice.reducer;
