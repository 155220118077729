const initialState = {
  isLogged: true,
  step: 1,
  canMoveToNextStep: false,
  previewIndex: 0,
  canVisualizeEntity: true,
  username: null,
  postLimitReached: false,
  entity: {
    images: null,
    items: null,
    itemsAreReady: false,
    checkedItems: [],
    loading: true,
    error: false,
    type: '',
    id: null,
  },
  description: {
    value: '',
    errorTextKey: '',
    showErrorBanner: false,
    error: false,
  },
  posting: {
    loading: false,
    error: false,
  },
  ai: {
    success: false,
    loading: false,
    error: false,
    saveForNextTime: false,
    isSmartEditingAvailable: false,
    isCheckingPlan: true,
    isCheckingForPreviousResults: true,
    werePreviousResults: false,
    wasFullPlanRequested: false,
    wasAnErrorRequestingFullPlan: false,
    isRequestFullPlanModalOpen: false,
    isRequestingFullPlan: false,
    showSuccessBanner: false,
    lastSavedConfiguration: {
      textLength: 2,
      textStyle: 2,
      emojiUsage: 2,
    },
    fields: {
      textLength: {
        id: 'textLength',
        labelKey: 'TextLength',
        options: [
          {
            id: 1,
            textKey: 'InstagramShortDescription',
            isActive: false,
          },
          {
            id: 2,
            textKey: 'InstagramMediumDescription',
            isActive: true,
          },
          {
            id: 3,
            textKey: 'InstagramLongDescription',
            isActive: false,
          },
        ],
      },
      textStyle: {
        id: 'textStyle',
        labelKey: 'TextStyle',
        options: [
          {
            id: 1,
            textKey: 'InstagramFormalDescription',
            isActive: false,
          },
          {
            id: 2,
            textKey: 'InstagramNeutralDescription',
            isActive: true,
          },
          {
            id: 3,
            textKey: 'InstagramFriendlyDescription',
            isActive: false,
          },
        ],
      },
      emojiUsage: {
        id: 'emojiUsage',
        labelKey: 'EmojiUsage',
        options: [
          {
            id: 1,
            textKey: 'InstagramNoEmojisDescription',
            isActive: false,
          },
          {
            id: 2,
            textKey: 'InstagramMinimunEmojisDescription',
            isActive: true,
          },
          {
            id: 3,
            textKey: 'InstagramManyEmojisDescription',
            isActive: false,
          },
        ],
      },
    },
  },
};

export default initialState;
