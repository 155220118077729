/* eslint-disable no-use-before-define */
/* eslint-disable no-promise-executor-return */
import { createSlice } from '@reduxjs/toolkit';
import {
  getDuplicateContacts,
  getAgentsChat,
  setMessengerUserDataAsync,
  messengerManager,
  getContact,
  getLeadsStatus,
  changeLeadStatus,
  getPropertiesToShare,
  getQuickResponses,
  getDevelopmentsToShare,
  deleteWebcontact,
  postCreateChannel,
  deleteMessage,
} from './services';
import {
  getAssignedFilter,
  getUnnassignedFilter,
  getWatcherFilterConfig,
} from './utils';

// Initial state
const initialState = {
  rightbar: false,
  isModalOpen: false,
  autoAsign: false,
  isLoadingWhatsAppUnlinked: true,
  openModalDeleteChannel: false,
  agentView: {
    isViewingOtherAgentsChats: false,
    selectedAgentViewChat: null,
  },
  messageReply: undefined,
  playingAudio: null,
  microphonePermissionDenied: false,
  CID: false,
  data: null,
  show: false,
  channelData: false,
  channelUpdated: false,
  user: null,
  quickResponses: { list: null, loading: false },
  whatsapp: {
    loading: false,
    qr: false,
    conected: false,
    showQRModal: false,
    showWhatsappAd: false,
    status: '',
  },
  unreads: { channels_amount: 0, whatsapp: 0, Colleague: 0, team: 0 },
  agents: {
    loading: false,
    asingLoader: false,
    list: [],
    agentSelected: [],
  },
  duplicateContacts: {
    loading: false,
    contacts: [],
    total: 0,
  },
  filters: {
    id: '',
    config: {},
    trayID: '',
    trayConfig: {},
    sort: { last_message_at: -1 },
  },
  permissions: {
    canSeeWebContacts: false,
  },
  contacts: [],
  leadsStatusList: [],
  shareProperties: {
    properties: [],
    pageInfo: false,
    loading: false,
    selectedProperty: false,
    show: false,
  },
  members: {
    loading: false,
    list: {},
  },
  attachments: {
    loading: false,
    media: [],
    file: [],
    link: [],
  },
  countAttachments: {
    cid: '',
    amount: 0,
  },
  modal: {
    type: null,
    props: {},
    messageId: null,
  },
};

// Actual Slice
export const MessengerSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChannelData(state, action) {
      state.channelData = action.payload;
    },

    setUnreads(state, action) {
      // Calcular el valor de channels_amount basado en el modo watcher
      const channelsAmount = state.agentView.isViewingOtherAgentsChats
        ? action.payload.whatsapp + action.payload.Colleague
        : action.payload.channels_amount;

      state.unreads = {
        ...action.payload,
        channels_amount: channelsAmount,
      };
    },

    setAttachments(state, action) {
      const { type, attachments } = action.payload;

      state.attachments.loading = action.payload.loading;
      state.attachments[type] = attachments.attachments;
    },

    setAutoAsign(state, action) {
      state.autoAsign = action.payload;
    },

    setCountAttachments(state, action) {
      state.countAttachments = action.payload;
    },

    setFilter(state, action) {
      const watcherAgentId = state.agentView?.selectedAgentViewChat?.id;

      const MEMBER = {
        $in: [watcherAgentId ? watcherAgentId.toString() : state.data.info.id],
      };

      const CONFIGS = {
        all: {
          type: state.filters.trayConfig,
          members: MEMBER,
        },

        assigned: getAssignedFilter(MEMBER, state.filters.trayID),

        unreads: {
          type: state.filters.trayConfig,
          members: MEMBER,
          has_unread: true,
        },

        active: {
          type: state.filters.trayConfig,
          members: MEMBER,
          frozen: false,
        },

        watcher: {
          type: getWatcherFilterConfig(
            state.filters.trayID,
            state.agentView.selectedAgentViewChat?.canManageWebContacts
          ),
          members: MEMBER,
          frozen: false,
        },

        unassigned: getUnnassignedFilter(MEMBER, state.filters.trayID),
      };

      state.filters.id = action.payload;

      state.filters.sort =
        action.payload === 'unreads'
          ? [{ has_unread: -1 }, { last_message_at: -1 }]
          : { last_message_at: -1 };

      state.filters.config = CONFIGS[action.payload];
    },

    setChannelTray(state, action) {
      const ALLTYPES = {
        $in: ['team', 'Colleague', 'WhatsAppContact', 'network'],
      };

      const WAPP = {
        $in: ['WhatsAppContact'],
      };

      if (state.permissions.canSeeWebContacts) {
        ALLTYPES.$in.push('WhatsAppGuest');
        WAPP.$in.push('WhatsAppGuest');
      }

      const CONFIGS = {
        all: ALLTYPES,

        whatsapp: WAPP,

        colleague: {
          $in: ['Colleague'],
        },
      };

      state.filters.trayID = action.payload;
      state.filters.trayConfig = CONFIGS[action.payload];

      MessengerSlice.caseReducers.setFilter(state, {
        type: 'chat/setFilter',
        payload: state.filters.id || 'active',
      });
    },

    resetDuplicated(state) {
      Object.assign(state.duplicateContacts, initialState.duplicateContacts);
    },

    showRightbar(state, action) {
      state.rightbar = action.payload;
    },

    setModalOpen(state, action) {
      state.isModalOpen = action.payload;
    },

    setUpdateAgentView(state, action) {
      state.agentView = {
        ...state.agentView,
        ...action.payload,
      };
    },

    setMessageReply(state, action) {
      state.messageReply = action.payload;
    },

    setMicrophonePermissionDenied(state, action) {
      state.microphonePermissionDenied = action.payload;
    },

    setSelectedAgent(state, action) {
      const { agentSelected } = state.agents;

      const hasAgent = agentSelected.find(
        (agent) => agent.id === action.payload?.id
      );

      if (hasAgent) {
        state.agents.agentSelected = agentSelected.filter(
          (agent) => agent.id !== action.payload.id
        );
      } else {
        state.agents.agentSelected = action.payload ? [action.payload] : [];
      }
    },

    setOpenModalDeleteChannel(state, action) {
      state.openModalDeleteChannel = action.payload;
    },

    setPermissions(state, action) {
      state.permissions = action.payload;
    },

    setChannelUpdated(state, action) {
      state.channelUpdated = action.payload;
    },

    setUserUpdated(state, action) {
      state.user = action.payload;
    },

    showTokkoChat(state, action) {
      state.show = action.payload;

      state.CID = action.payload?.CID;

      if (!action.payload) {
        Object.assign(state, {
          ...initialState,
          data: state.data,
          filters: state.filters,
        });
      }
    },

    setShowModalWhatsapp(state, action) {
      const { value, key } = action.payload;

      state.whatsapp[key] = value;
      state.isLoadingWhatsAppUnlinked = false;
    },

    setPlayingAudio(state, action) {
      state.playingAudio = action.payload;
    },
    stopPlayingAudio(state) {
      state.playingAudio = null;
    },

    sharePropertiesChangeValues(state, action) {
      const { key, value } = action.payload;

      state.shareProperties[key] = value;

      if (key === 'show' && !value) {
        Object.assign(state.shareProperties, initialState.shareProperties);
      }
    },

    openModal: (state, action) => {
      state.modal.type = action.payload.type;
      state.modal.props = action.payload.props || {};
      state.modal.messageId = action.payload.messageId || null;
    },

    closeModal: (state) => {
      state.modal.type = null;
      state.modal.props = {};
      state.modal.messageId = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(setMessengerUserDataAsync.fulfilled, (state, action) => {
      state.data = action.payload;

      MessengerSlice.caseReducers.setChannelTray(state, {
        type: 'chat/setChannelTray',
        payload: 'all',
      });
    });

    builder // get agents
      .addCase(getAgentsChat.pending, (state) => {
        state.agents.loading = true;
      })
      .addCase(getAgentsChat.fulfilled, (state, action) => {
        state.agents.loading = false;
        state.agents.list = action.payload.users;
      });

    builder // get duplicate contacts
      .addCase(getDuplicateContacts.pending, (state) => {
        state.duplicateContacts.loading = true;
      })
      .addCase(getDuplicateContacts.fulfilled, (state, action) => {
        state.duplicateContacts = { ...action.payload, loading: false };
      });

    builder // messenger Manager
      .addCase(messengerManager.pending, (state) => {
        state.agents.asingLoader = true;
      })
      .addCase(messengerManager.fulfilled, (state) => {
        state.agents.asingLoader = false;
      });

    builder // get contacts
      .addCase(getContact.fulfilled, (state, action) => {
        const newContact = action.payload.contacts[0];

        const existingIndex = state.contacts.findIndex(
          (contact) => contact?.id === newContact?.id
        );

        if (existingIndex !== -1) {
          state.contacts[existingIndex] = newContact;
        } else {
          state.contacts.push(newContact);
        }
      });

    builder // get leads status
      .addCase(getLeadsStatus.fulfilled, (state, action) => {
        state.leadsStatusList = action.payload;
      });

    builder // change lead status
      .addCase(changeLeadStatus.fulfilled, (state) => {
        state.loading = false;
      });

    builder // get properties to share
      .addCase(getPropertiesToShare.pending, (state) => {
        state.shareProperties.loading = true;
      })
      .addCase(getPropertiesToShare.fulfilled, (state, action) => {
        const { properties } = state.shareProperties;

        state.shareProperties.loading = false;
        state.shareProperties.pageInfo = action.payload.page_info;

        const newProperties = action.payload.properties.filter(
          (newProperty) =>
            !properties.some(
              (existingProperty) => existingProperty.id === newProperty.id
            )
        );

        state.shareProperties.properties = [...properties, ...newProperties];
      });

    builder // get developments to share
      .addCase(getDevelopmentsToShare.pending, (state) => {
        state.shareProperties.loading = true;
      })
      .addCase(getDevelopmentsToShare.fulfilled, (state, action) => {
        const { properties } = state.shareProperties;

        state.shareProperties.loading = false;
        state.shareProperties.pageInfo = action.payload.page_info;

        const newDevelopments = action.payload.results.filter(
          (newDevelopment) =>
            !properties.some(
              (existingDevelopment) =>
                existingDevelopment.id === newDevelopment.id
            )
        );

        state.shareProperties.properties = [...properties, ...newDevelopments];
      });

    builder // get quick responses
      .addCase(getQuickResponses.pending, (state) => {
        state.quickResponses.loading = true;
      })
      .addCase(getQuickResponses.fulfilled, (state, action) => {
        state.quickResponses.loading = false;
        state.quickResponses.list = action.payload;
      });

    builder.addCase(deleteWebcontact.fulfilled, () => {
      // solo llamamos al endpoint
    });

    // post create channel
    builder.addCase(postCreateChannel.fulfilled, () => {
      // solo llamamos al endpoint
    });

    // delete message
    builder.addCase(deleteMessage.fulfilled, () => {
      // solo llamamos al endpoint
    });
  },
});

export const {
  setChannelData,
  showTokkoChat,
  setShowQRModal,
  setShowWhatsappAd,
  setShowModalWhatsapp,
  setSelectedAgent,
  showRightbar,
  setMessageReply,
  resetDuplicated,
  setFilter,
  setPermissions,
  sharePropertiesChangeValues,
  setChannelUpdated,
  setUserUpdated,
  setChannelTray,
  setPlayingAudio,
  stopPlayingAudio,
  setMicrophonePermissionDenied,
  setUnreads,
  setModalOpen,
  setAttachments,
  setCountAttachments,
  setUpdateAgentView,
  setAutoAsign,
  setOpenModalDeleteChannel,
  openModal,
  closeModal,
} = MessengerSlice.actions;

export default MessengerSlice.reducer;
