import ApiService from '@/services/ApiService';

class InstagramService {
  static async getEntityData({ type, id }) {
    const composedURL = `/api3/publishable?object_model=${type}&object_id=${id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async postPublication(payload) {
    const composedURL = `/meta_platforms/instagram/publish/`;
    const response = await ApiService.post(composedURL, payload);
    return response;
  }

  static async getIsUserLogged() {
    const composedURL = `/meta_platforms/credential/verify/`;
    const response = await ApiService.post(composedURL);
    return response;
  }

  static async getIfPlanUpgradeWasRequested() {
    const composedURL = `/api3/company/upgrade_pending`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async requestFullPlan(payload) {
    const composedURL = `/api3/company/upgrade_pending`;
    const response = await ApiService.put(composedURL, payload);
    return response;
  }

  static async generateInstagramAIDescription(payload) {
    const composedURL = `/meta_platforms/openai/description/`;
    const response = await ApiService.post(composedURL, payload);
    return response;
  }

  static async getPublications(id) {
    const composedURL = `/meta_platforms/instagram/publication/?object_id=${id}&object_model=PRO&page=1`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getInstagramProperties({ filter_type = 1, page = 1 }) {
    const composedURL = `/api3/instagram/property/?filter_type=${filter_type}&page=${page}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getInstagramPropertiesCount() {
    const composedURL = `/api3/instagram/property/count/`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getInstagramAIConfiguration({ type, id }) {
    const composedURL = `/meta_platforms/openai/description/?object_model=${type}&object_id=${id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async deleteInstagramPublication(id) {
    const composedURL = `/meta_platforms/instagram/publication/${id}/delete/`;
    const response = await ApiService.post(composedURL);
    return response;
  }
}

export default InstagramService;
